<template>
  <table class="additional-fee-management-table ss-table">
    <thead>
      <tr>
        <th
          v-for="(header, i) in headers"
          :key="i"
          :style="{ width: header.width }"
        >
          <bg-text size="body-3">{{ header.label }}</bg-text>
        </th>
      </tr>
    </thead>

    <tbody>
      <template v-if="isLoading">
        <tr v-for="i in 5" :key="i">
          <td v-for="j in headers.length" :key="j">
            <bg-skeleton height="48px" width="100%" />
          </td>
        </tr>
      </template>

      <template v-else-if="!isError && additionalFees.length">
        <additional-fee-management-table-row
          v-for="additionalFee in additionalFees"
          :key="additionalFee.id"
          :additional-fee="additionalFee"
          v-on="$listeners"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import { BgText, BgSkeleton } from 'bangul-vue';

import AdditionalFeeManagementTableRow from '../AdditionalFeeManagementTableRow';

export default {
  name: 'AdditionalFeeManagementTable',

  components: {
    BgText,
    BgSkeleton,
    AdditionalFeeManagementTableRow,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    additionalFees: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        { label: 'ID', width: '80px' },
        { label: 'Nama Biaya', width: '240px' },
        { label: 'Tipe Pembayaran Biaya', width: '160px' },
        { label: 'Satuan Biaya', width: '288px' },
        { label: 'Waktu Penentuan Harga Biaya', width: '232px' },
        { label: 'Penyewa Bisa Pilih Mandiri', width: '160px' },
        { label: 'Action', width: '80px' },
      ],
    };
  },
};
</script>

<style lang="scss" src="./AdditionalFeeManagementTable.scss" scoped></style>
