<template>
  <div class="additional-fee-management-top-bar">
    <bg-grid class="mb-32" :v-align="'center'">
      <bg-grid-item class="mb-0">
        <property-search-bar
          v-model="searchKeyword"
          placeholder="Cari berdasarkan Nama Biaya"
          url=""
        />
      </bg-grid-item>
      <bg-grid-item class="mb-0">
        <bg-button
          size="lg"
          class="additional-fee-management-top-bar__filter-button"
        >
          <bg-badge-counter
            v-if="totalFilter"
            :value="totalFilter"
            variant="black"
          />
          <bg-icon v-else name="filter" size="sm" />
          <span class="ml-8">Filter</span>
        </bg-button>
        <bg-button
          size="lg"
          class="ml-16 additional-fee-management-top-bar__filter-button"
          :disabled="isLoading || !totalFilter"
        >
          Reset
        </bg-button>
        <bg-button
          size="lg"
          class="ml-16 additional-fee-management-top-bar__filter-button"
          variant="primary"
          :disabled="isLoading"
        >
          Cari
        </bg-button>
      </bg-grid-item>

      <bg-grid-item class="mb-0 ta-r" :col="3">
        <router-link :to="{ name: 'additional-fee-management.add' }">
          <bg-button
            class="additional-fee-management-top-bar__add-button"
            size="lg"
            variant="secondary"
          >
            Tambah Biaya Tambahan
          </bg-button>
        </router-link>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgButton,
  BgBadgeCounter,
  BgIcon,
} from 'bangul-vue';
import PropertySearchBar from '@admin_molecules/PropertySearchBar';

export default {
  name: 'AdditionalFeeManagementTopBar',
  components: {
    BgGrid,
    BgGridItem,
    BgButton,
    BgBadgeCounter,
    BgIcon,
    PropertySearchBar,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      totalFilter: 0,
      searchKeyword: '',
    };
  },
};
</script>

<style lang="scss" src="./AdditionalFeeManagementTopBar.scss" scoped></style>
