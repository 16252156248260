<template>
  <div class="additional-fee-management bg-u-mx-md">
    <div class="flex align-center justify-space-between bg-u-mb-xxl">
      <bg-text size="heading-2" tag="h2"> Manajemen Biaya Tambahan </bg-text>
      <!-- TODO: on hold until the changelog page is implemented -->
      <router-link :to="{ name: '' }">
        <bg-button size="lg">Riwayat Perubahan</bg-button>
      </router-link>
    </div>

    <additional-fee-management-top-bar :is-loading="isLoading" />

    <div class="additional-fee-management__table-container">
      <additional-fee-management-table
        :additional-fees="additionalFees"
        :is-loading="isLoading"
        :is-error="isError"
        @open-delete-modal="handleOpenDeleteModal"
      />

      <div
        v-if="isError || (!isLoading && !additionalFees.length)"
        class="additional-fee-management__table-empty-state bg-u-pt-xxxl bg-u-pb-md bg-u-px-md"
      >
        <bg-text size="title-2">Data Tidak Ditemukan</bg-text>
        <bg-text size="body-2" class="bg-u-mt-sm">
          Data tidak ditemukan di filter atau kata kunci yang Anda gunakan.
        </bg-text>
      </div>
    </div>

    <bg-pagination
      v-if="!isError && !isLoading && totalPage > 1"
      class="additional-fee-management__pagination"
      data-testid="additionalFeePagination"
      :value="currentPage"
      :page-total="totalPage"
      @input="getAdditionalFee"
    />

    <additional-fee-management-delete-modal
      :is-open.sync="isDeleteModalOpen"
      :data="deleteModalData"
      @action-success-delete="getAdditionalFee"
    />
  </div>
</template>

<script>
import { BgText, BgPagination, BgButton } from 'bangul-vue';
import { endpoints } from '@admin_endpoints/additional-fee';

import AdditionalFeeManagementTopBar from './components/AdditionalFeeManagementTopBar';
import AdditionalFeeManagementTable from './components/AdditionalFeeManagementTable';
import AdditionalFeeManagementDeleteModal from './components/AdditionalFeeManagementDeleteModal';

export default {
  name: 'AdditionalFeeManagement',

  components: {
    BgText,
    BgPagination,
    BgButton,
    AdditionalFeeManagementTopBar,
    AdditionalFeeManagementTable,
    AdditionalFeeManagementDeleteModal,
  },

  data() {
    return {
      isLoading: false,
      isError: false,
      additionalFees: [],
      additionalFeesOffset: 0,
      additionalFeesLimit: 10,
      totalAdditionalFees: 0,
      isDeleteModalOpen: false,
      deleteModalData: {},
    };
  },

  computed: {
    currentPage() {
      return this.additionalFeesOffset / this.additionalFeesLimit + 1;
    },
    totalPage() {
      return Math.ceil(this.totalAdditionalFees / this.additionalFeesLimit);
    },
  },

  methods: {
    async getAdditionalFee(page = 1) {
      this.isLoading = true;
      this.isError = false;
      this.totalAdditionalFees = 0;
      this.additionalFeesOffset = (page - 1) * this.additionalFeesLimit;

      try {
        const params = {
          offset: this.additionalFeesOffset,
          limit: this.additionalFeesLimit,
        };
        const response = await endpoints.getAdditionalFeeList(params);

        this.additionalFees = response?.additional_fees || [];
        this.totalAdditionalFees = response?.metadata.total || 0;
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },

    handleOpenDeleteModal(data) {
      this.isDeleteModalOpen = true;
      this.deleteModalData = data;
    },
  },

  created() {
    this.getAdditionalFee();
  },
};
</script>

<style lang="scss" src="./AdditionalFeeManagement.scss" scoped></style>
