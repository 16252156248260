<template>
  <tr class="table-row additional-fee-management-table-row">
    <td>AF-{{ additionalFee.id }}</td>
    <td>{{ additionalFee.name }}</td>
    <td>
      <bg-label-rainbow
        v-if="additionalFee.payment_type"
        :color="paymentType[additionalFee.payment_type].color"
      >
        {{ paymentType[additionalFee.payment_type].text }}
      </bg-label-rainbow>
    </td>
    <td>
      <div v-if="unitCost.time.length" size="body-4">
        <span class="bg-u-text-neutral-500 bg-u-mr-xxs">Waktu:</span>
        <span>{{ unitCost.time }}</span>
      </div>
      <div
        v-if="unitCost.fee.length"
        size="body-4"
        :class="{ 'bg-u-mt-sm': unitCost.time.length }"
      >
        <span class="bg-u-text-neutral-500 bg-u-mr-xxs">Besaran:</span>
        <span>{{ unitCost.fee }}</span>
      </div>
    </td>
    <td>
      <bg-label-rainbow
        :color="pricingTime[additionalFee.pricing_method].color"
      >
        {{ pricingTime[additionalFee.pricing_method].text }}
      </bg-label-rainbow>
    </td>
    <td>
      <bg-label-rainbow
        :color="additionalFee.is_chooseable_by_tenant ? 'green' : 'yellow'"
      >
        {{ additionalFee.is_chooseable_by_tenant ? 'Ya' : 'Tidak' }}
      </bg-label-rainbow>
      <div
        v-if="
          additionalFee.is_chooseable_by_tenant && additionalFee.funnel.length
        "
        class="bg-u-text-neutral-500 bg-u-mt-xxs"
      >
        <span>{{ funnelFormatted }}</span>
      </div>
    </td>

    <td>
      <table-action-menu>
        <bg-list-item
          v-for="action in actions"
          :key="action.icon"
          role="button"
          clickable
          @click="action.onClick"
        >
          <bg-text size="body-2">{{ action.label }}</bg-text>
        </bg-list-item>
      </table-action-menu>
    </td>
  </tr>
</template>

<script>
import { BgText, BgListItem, BgLabelRainbow } from 'bangul-vue';
import { capitalize } from 'Utils/typography';

import TableActionMenu from '@admin_molecules/TableActionMenu';

export default {
  name: 'AdditionalFeeManagementTableRow',

  components: {
    BgText,
    BgListItem,
    BgLabelRainbow,
    TableActionMenu,
  },

  props: {
    additionalFee: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      paymentType: {
        fixed: {
          text: 'Tetap',
          color: 'blue',
        },
        once: {
          text: 'Satu Kali',
          color: 'yellow',
        },
      },
      pricingTime: {
        predefined: {
          text: 'Ditentukan di Awal',
          color: 'green',
        },
        defined_by_billing: {
          text: 'Disesuaikan dengan Tagihan',
          color: 'yellow',
        },
      },
      timeUnit: {
        daily: 'Harian',
        weekly: 'Mingguan',
        monthly: 'Bulanan',
        yearly: 'Tahunan',
      },
      feeUnit: {
        kg: 'Kilogram (kg)',
        item: 'Item/pcs',
        person: 'Per orang',
      },
    };
  },

  computed: {
    unitCost() {
      const timeUnitFormatted = this.additionalFee.time_unit
        .map(item => this.timeUnit[item])
        .join(', ');

      const feeUnitFormatted = this.additionalFee.fee_unit
        .map(item => this.feeUnit[item])
        .join(', ');

      return { time: timeUnitFormatted, fee: feeUnitFormatted };
    },
    funnelFormatted() {
      return this.additionalFee.funnel.map(capitalize).join(' dan ');
    },
    actions() {
      return [
        {
          label: 'Ubah',
          onClick: () => {
            // TODO: on hold until the edit page is implemented
            alert(`hai edit item with id: ${this.additionalFee.id}`);
          },
        },
        {
          label: 'Hapus',
          onClick: () => {
            this.$emit('open-delete-modal', {
              name: this.additionalFee.name,
              id: this.additionalFee.id
            });
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss" src="./AdditionalFeeManagementTableRow.scss" scoped></style>
