<template>
  <bg-modal
    :value="isOpen"
    class="additional-fee-management-delete-modal"
    title="Yakin menghapus biaya tambahan?"
    description="Biaya tambahan yang dihapus tidak dapat dikembalikan."
    @close="closeModal"
  >
    <bg-text size="title-5" class="bg-u-mt-xl">Nama Biaya</bg-text>
    <bg-text size="body-2" class="bg-u-mt-xxs" data-testid="fee-name">{{
      data.name
    }}</bg-text>

    <bg-text size="title-5" class="bg-u-mt-xl">ID</bg-text>
    <bg-text size="body-2" class="bg-u-mt-xxs" data-testid="fee-id"
      >AF-{{ data.id }}</bg-text
    >

    <template #footer>
      <div class="flex justify-end">
        <bg-button size="lg" class="bg-u-mr-md" @click="closeModal">
          Kembali
        </bg-button>
        <bg-button
          size="lg"
          variant="primary"
          :loading="isLoading"
          @click="handleDeleteItem"
        >
          Hapus
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgModal, BgText, BgButton } from 'bangul-vue';
import { endpoints } from '@admin/api/endpoints/additional-fee.js';

export default {
  name: 'AdditionalFeeManagementDeleteModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: /* istanbul ignore next */ () => {},
    },
  },

  components: {
    BgModal,
    BgText,
    BgButton,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
    },

    async handleDeleteItem() {
      this.isLoading = true;

      const response = await endpoints.deleteAdditionalFee(this.data.id);
      if (response) {
        this.$emit('action-success-delete');
        this.closeModal();
      }

      this.isLoading = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
  src="./AdditionalFeeManagementDeleteModal.scss"
></style>
